import { Component, ElementRef, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { SlideViewerFacade } from '@medsurf/flat-facades';
import * as FlatModels from '@medsurf/flat-models';
import { MediaControlService } from '@medsurf/flat-services';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import * as FlatActions from '@medsurf/flat-actions';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'medsurf-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnDestroy {
  /**
   * Inputs
   */
  @Input() public media: FlatModels.MediaEntityModels.MediaEntityType;

  /**
   * Members
   */
  public errorState = false;
  public progress = 0;
  public isSeeking = false;
  public isPlaying = false;
  public loop = false;
  
  public playbackSpeed = [0.25, 0.5, 1, 1.25, 1.5, 1.75, 2];
  public speed = 1;
  
  private _destroyed = new Subject<boolean>();

  /**
   * View Children
   */
  @ViewChild('progressWrap', {static: false}) public progressElementRef: ElementRef;
  @ViewChild('videoElement', {static: false}) public videoElementRef: ElementRef;

  public adjustPlaybackSpeed(playbackSpeed: number){
    this.speed = playbackSpeed;
    this.videoElementRef.nativeElement.playbackRate = this.speed;
  }

  public toggleEndlessLoop() {
    this.loop = !this.loop;
    this.videoElementRef.nativeElement.loop = this.loop;
  }

  constructor(
    public mediaControlService: MediaControlService, 
    public slideViewerFacade: SlideViewerFacade, 
    public actions$: Actions) {

      this.actions$.pipe(
        ofActionSuccessful(FlatActions.MediaInteractionViewerActions.JumpToTime),
        takeUntil(this._destroyed)
      ).subscribe(({ time, force }: FlatActions.MediaInteractionViewerActions.JumpToTime) => {
        this.videoElementRef.nativeElement.currentTime = time;
        if (force === 'pause') {
          this.videoElementRef.nativeElement.pause();
        }
        if (force === 'play') {
          this.videoElementRef.nativeElement.play();
        }
      })

      this.actions$.pipe(
        ofActionSuccessful(FlatActions.MediaInteractionViewerActions.TogglePlay),
        takeUntil(this._destroyed)
      ).subscribe(({ force }: FlatActions.MediaInteractionViewerActions.TogglePlay) => {
        if (force === 'play') {
          this.videoElementRef.nativeElement.play();
        } else if (force === 'pause') {
          this.videoElementRef.nativeElement.pause();
        } else {
          this.videoElementRef.nativeElement.paused ? this.videoElementRef.nativeElement.play() : this.videoElementRef.nativeElement.pause();
        }
      })
  }

  public localToggle() {
    if (!this.videoElementRef.nativeElement && !this.videoElementRef.nativeElement.paused) {
      return;
    }
    if (this.videoElementRef.nativeElement.paused === true) {
      this.videoElementRef.nativeElement.play();
    } else {
      this.videoElementRef.nativeElement.pause();
    }
  }

  public localSeekBarMousedown(event: MouseEvent) {
    this.isSeeking = true;
    this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
  }

  private seek(value: number): void {
    this.videoElementRef.nativeElement.currentTime = this.videoElementRef.nativeElement.duration / value;
  }

  public updateProgress(): void {
    const currentTime = this.videoElementRef?.nativeElement?.currentTime || 0;
    const duration = this.videoElementRef?.nativeElement?.duration || 0;
    this.slideViewerFacade.requestSetCurrentTime(currentTime);
    this.progress = duration ? (1 / duration) * currentTime : 0;
  }

  @HostListener('window:mousemove', ['$event'])
  public handleMouseMove(event: MouseEvent) {
    if (this.isSeeking) {
      this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
    }
  }

  @HostListener('window:mouseup', ['$event'])
  public handleMouseUp(event: MouseEvent) {
    if (this.isSeeking) {
      this.isSeeking = false;
      this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
    }
  }

  @HostListener('contextmenu', ['$event'])
  public onRightClick(event: Event) {
    event.preventDefault();
  }


  @HostListener('window:keydown.space')
  public handleSpaceKey() {
    this.localToggle();
  }

  public ngOnDestroy() {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
