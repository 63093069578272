<ng-template #modalElement>
  <div class="modal-header" [class.no-title]="!currentModalTitle">
    <h4 class="modal-title">{{ currentModalTitle }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <i class="fal fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <medsurf-text-format [text]="currentModalContent"></medsurf-text-format>
  </div>
</ng-template>
  