<!-- Alert -->
<medsurf-control-alert></medsurf-control-alert>

<!-- Cookies -->
<medsurf-cookie-consent *ngIf="(menuViewerFacade.showPrint$ | async) === false"></medsurf-cookie-consent>

<!-- Notice -->
<medsurf-notice [includes]='["elearnings", "chapters", "subchapters", "blocks"]'></medsurf-notice>

<div class="router-container" [class.blur-35]="modalControlService.showModal || feedbackControlService.showFeedbackModal">
  <!-- Navbar-->
  <medsurf-navbar *ngIf="(menuViewerFacade.showFullscreen$ | async) === false && (menuViewerFacade.showPrint$ | async) === false"></medsurf-navbar>

  <!-- Spinner -->
  <medsurf-control-spinner class="text-black" *ngIf="isLoader"></medsurf-control-spinner>

  <!-- Router Outlet -->
  <router-outlet></router-outlet>

  <!-- General Modal -->
  <medsurf-general-modal></medsurf-general-modal>

  <!-- footer -->
  <!--
  <medsurf-footer class="d-none d-md-inline"
                  [links]="footerLinks"
                  align="center">
  </medsurf-footer>
  -->
</div>

<!-- Modal -->
<medsurf-modal></medsurf-modal>

<!-- Feedback -->
<medsurf-feedback></medsurf-feedback>
