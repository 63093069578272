import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortBy_shared' })
export class SortBySharedPipe implements PipeTransform {
  transform<T extends {[key: string]: string | number} | number | string>(value: T[], order?: 'asc' | 'desc', column?: keyof T) {

    if (!Array.isArray(value)) return value;
    if (value.length <= 1) return value;

    const isDesc = order === 'desc';

    let sortedArray = [...value];
    if (!column || column === '') {
      sortedArray.sort((a, b) => {
        const aa = isDesc ? b : a;
        const bb = isDesc ? a : b;
        const isNumeric = typeof aa === 'number' && typeof bb === 'number';
        return isNumeric ? aa - bb : `${aa}`.localeCompare(`${bb}`);
      })
    } else {
      sortedArray.sort((a, b) => {
        const aa = isDesc ? b[column] : a[column];
        const bb = isDesc ? a[column] : b[column];
        const isNumeric = typeof aa === 'number' && typeof bb === 'number';
        return isNumeric ? aa - bb : `${aa}`.localeCompare(`${bb}`);
      })
    }
    return sortedArray;
  }
}