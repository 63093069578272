import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { AppSharedFacade, SlideViewerFacade } from '@medsurf/flat-facades';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import * as FlatModels from '@medsurf/flat-models'
import { MediaInteractionViewerActions } from '@medsurf/flat-actions';

@Component({
  selector: 'medsurf-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss']
})
export class GeneralModalComponent implements OnDestroy {

  /**
   * Members
   */
  public modals = new Map<string, number>();
  public currentModalTitle = '';
  public currentModalContent = '';
  public modalRef: NgbModalRef;


  private _destroyed = new Subject<boolean>();

  /**
   * View Children
   */
  @ViewChild('modalElement') modalElement: TemplateRef<any>;

  public constructor(
    public appSharedFacade: AppSharedFacade,
    public slideViewerFacade: SlideViewerFacade,
    public modalService: NgbModal,
    public actions$: Actions) {

    this.actions$.pipe(
      ofActionSuccessful(MediaInteractionViewerActions.OpenModal),
      takeUntil(this._destroyed)
    ).subscribe(({ targetId, onClose }: MediaInteractionViewerActions.OpenModal) => {
      const event = this.slideViewerFacade.snapshot_eventById(targetId);
      if (event?.type === 'modal') {
        this.openModal(event, true, onClose);
      }
    })


    this.slideViewerFacade.currentTime$.pipe(takeUntil(this._destroyed)).subscribe((currentTime) => {
      for (const [id, from] of this.modals) {
        if (currentTime < from) {
          this.modals.delete(id);
        }
      }  
    })
      
    this.slideViewerFacade.currentActiveEvents$.pipe(takeUntil(this._destroyed)).subscribe((events) => {
      events?.forEach(event => {
        if (event.type === 'modal') {
          this.openModal(event);
        }
      })
    })
  }

  public openModal(event: FlatModels.MediaInteractionViewerModels.MediaInteractionModalEvent, force: boolean = false, onClose?: MediaInteractionViewerActions.OpenModal['onClose']) {
    if (!this.modals.has(event.id) || force) {
      this.modals.set(event.id, event.from);
      // Wait for modal to open before pausing
      setTimeout(() => {
        this.slideViewerFacade.requestTogglePlay('pause');
      }, 500);
      this.modalRef = this.modalService.open(this.modalElement, {
        size: 'lg',
        scrollable: true,
        centered: true,
      })
      this.currentModalContent = event.content;
      this.currentModalTitle = event.title;

      this.modalRef.result.finally(() => {
        if (onClose?.type) {
          this.slideViewerFacade.requestTogglePlay(onClose.type);

          if (onClose.value !== undefined) {
            this.slideViewerFacade.requestJumpToTime(onClose.value);
          }
        } else {
          this.slideViewerFacade.requestTogglePlay('play');
        }
        this.currentModalContent = '';
        this.currentModalTitle = '';
      })
    }
  }

  public closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = null;
    }
  }

  public ngOnDestroy() {
    this._destroyed.next(true);
    this._destroyed.complete();
  }
}
